import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Accordion, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

interface ITogCharacterNodes {
  nodes: ITOGCharacter[];
}

interface ITogCharacterEntry {
  allCharacters: ITogCharacterNodes;
}

interface IProps {
  data: ITogCharacterEntry;
}

import './tier-list.scss';
import { ITOGCharacter } from '../../modules/common/model/graphql-types';
import { TOGCharacter } from '../../modules/tog/common/components/tog-character';

const ToGTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          },
          {
            value: 'SSR+',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssrplus.png"
                height={24}
                alt="SSR+ rarity"
              />
            )
          },
          {
            value: 'UR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ur.png"
                height={24}
                alt="UR rarity"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_phys.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Magical',
            tooltip: 'Magical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_magic.png"
                width={24}
                alt="Magical"
              />
            )
          }
        ]
      },
      {
        key: 'color',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Yellow',
            tooltip: 'Yellow',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_yellow.png"
                width={24}
                alt="Yellow"
              />
            )
          },
          {
            value: 'Purple',
            tooltip: 'Purple',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_purple.png"
                width={24}
                alt="Purple"
              />
            )
          },
          {
            value: 'Red',
            tooltip: 'Red',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_red.png"
                width={24}
                alt="Red"
              />
            )
          },
          {
            value: 'Blue',
            tooltip: 'Blue',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_blue.png"
                width={24}
                alt="Blue"
              />
            )
          },
          {
            value: 'Green',
            tooltip: 'Green',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_green.png"
                width={24}
                alt="Green"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          },
          {
            value: 'Assassin',
            tooltip: 'Assassin',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_assassin.png"
                width={24}
                alt="Assassin"
              />
            )
          },
          {
            value: 'Ranged',
            tooltip: 'Ranged',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_ranged.png"
                width={24}
                alt="Ranged"
              />
            )
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.color && activeFilters.color !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.color.toLowerCase() === activeFilters.color.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'low', label: 'Low' },
    { value: 'high', label: 'High' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke tog-tier '}
      game="tog"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/tog/categories/category_tier.png"
            alt="Tier"
          />
        </div>
        <div className="page-details">
          <h1>Tower of God: New World Tier list</h1>
          <h2>
            Tier list for Tower of God: New World that rates all characters.
          </h2>
          <p>
            Last updated: <strong>06/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke">
        <SectionHeader title="Tier List" />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Introduction</Accordion.Header>
            <Accordion.Body>
              <h5>Special thanks</h5>
              <p>
                The tier list was created with the help of{' '}
                <strong>Conowen</strong> who shared his thoughts about the
                characters and their performance with us. You can find his
                YouTube channel{' '}
                <a
                  href="https://www.youtube.com/@Conowen96"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{' '}
                and his discord{' '}
                <a
                  href="https://discord.com/invite/gVDnRNa6ey"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                . On his channel, Conowen is posting reviews for every new
                character, guides for bosses and he even does account reviews on
                his Discord. Do check him out!
              </p>
              <h5>About the tier list</h5>
              <p>
                The tier list rates characters based on their{' '}
                <strong>Adventure</strong> performance in both low and high
                deficit. The 300 value means the difference between your highest
                link and the enemy level.
              </p>
              <p>
                Also, please compare characters within their Color. ToG: New
                World is a very team-building focused game and if you create a
                team made out of random Colored characters but with higher
                rarity it may perform worse than a mono or duo Colored team. Use
                the switcher to change to the tier list you're interested in -
                the <strong>Story</strong> one is the one you will see by
                default.
              </p>
              <p>Criteria:</p>
              <ul>
                <li>
                  <strong>SSR</strong> characters are rated at Rainbow tier
                  (+21) with their Exclusive Equipment upgraded to +30,
                </li>
                <ul>
                  <li>
                    Still, some characters work well even before that and in
                    that case we have marked the minimum investment needed in
                    the top right corner,
                  </li>
                </ul>
                <li>
                  <strong>SSR+</strong> and <strong>UR</strong> characters are
                  rated at Rainbow tier (+21). Also, similar to SSR characters,
                  we're assuming that the Exclusive Equipment is upgraded to
                  +30.
                </li>
              </ul>
              <p>
                When it comes to <strong>Revolution</strong>, for every
                character we're assuming a different investment threshold that
                unlocks their strongest powerspikes, but showing it on the tier
                list would introduce too big visual clutter. Instead, head to
                the{' '}
                <a
                  href="https://docs.google.com/spreadsheets/u/0/d/1paOxiwoKOzzR0E5W_r1SYLVuliJomnz8BkNQt8vNhQU/htmlview#"
                  target="_blank"
                  rel="noreferrer"
                >
                  sheet
                </a>{' '}
                made by <strong>Conowen</strong> and the Investment tab - there
                you will find the info.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
        <div className="tier-list-header">
          <p className="title">
            Tier List <span>(Adventure)</span>
          </p>
          <p className="sub-title">
            You're currently viewing the <strong>Adventure</strong> tier list.
            It shows how a character performs in Adventure{' '}
            {sortOption === 'low' && 'at low deficit so anything below 300.'}
            {sortOption === 'high' && 'at high deficit, so anything above 300.'}
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option story ${sortOption === 'low' && 'selected'}`}
            onClick={() => setSortOption('low')}
          >
            <p>Adventure (Deficit &lt; 300)</p>
          </div>
          <div
            className={`option pvp ${sortOption === 'high' && 'selected'}`}
            onClick={() => setSortOption('high')}
          >
            <p>Adventure (Deficit &gt; 300)</p>
          </div>
        </div>
        <div className="employees-filter-bar nikke">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-tog">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-header purple">
                <span>Purple</span>
              </div>
              <div className="custom-header red">
                <span>Red</span>
              </div>
              <div className="custom-header blue">
                <span>Blue</span>
              </div>
              <div className="custom-header green">
                <span>Green</span>
              </div>
            </div>
          </div>
          <div className="custom-tier tier-ss first">
            <div className="tier-rating ss">
              <span>SS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s-plus">
            <div className="tier-rating s-plus">
              <span>S+</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-a">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-b">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-c">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-d">
            <div className="tier-rating d">
              <span>D</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-e">
            <div className="tier-rating e">
              <span>E</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-f">
            <div className="tier-rating f">
              <span>F</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '3')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '3')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '3')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '3')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '3')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ToGTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Tower of God: New World | Prydwen Institute"
    description="Tier list for Tower of God: New World that rates all characters."
    game="tog"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulTogCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        color
        type
        class
        rarity
        notReleased
        ratings {
          low
          high
        }
        goodOnBosses
        investment
      }
    }
  }
`;
